<template>
    <div class="dataAnalysisService">
        <div class="dataAnalysisService-header" v-if="showBreadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right" v-if="role == 0">
                <el-breadcrumb-item :to="{ path: '/admin/datacenter' }">数据中心</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/admin/datacenter' }">得分榜单</el-breadcrumb-item>
                <el-breadcrumb-item>学生考试详情</el-breadcrumb-item>
            </el-breadcrumb>
            <el-breadcrumb separator-class="el-icon-arrow-right" v-if="role == 1">
                <el-breadcrumb-item :to="{ path: '/schooladmin/datacenter' }">数据中心</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/schooladmin/datacenter' }">得分榜单</el-breadcrumb-item>
                <el-breadcrumb-item>学生考试详情</el-breadcrumb-item>
            </el-breadcrumb>
            <el-breadcrumb separator-class="el-icon-arrow-right" v-if="role == 2">
                <el-breadcrumb-item :to="{ path: '/trainadmin/datacenter' }">数据中心</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/trainadmin/datacenter' }">得分榜单</el-breadcrumb-item>
                <el-breadcrumb-item>学生考试详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div v-if="dataArr.length != 0" class="dataAnalysisService_content">
            <el-scrollbar style="height:100%">
                <div class="dataAnalysisService_content_box" v-for="(item, index) in dataArr" :key="item.title">
                    <div class="title">
                        <div style="width: 4px;height: 16px;background-color: #1122D8;border-radius: 2px;margin-right: 10px"></div>
                        <div>{{item.module}}</div>
                        <div style="margin-left: 20px;font-size: 14px" v-if="scoreShow">
                            得分（{{item.data.score ? item.data.score : 0}}）
                        </div>
                    </div>
                    <div class="subject">
                        <p style="min-width: 48px; margin: 0; font-size: 18px; font-weight: 700">题目：</p>
                        <p style="margin: 0; font-size: 17px; padding-right: 10px;" class="newLine">
                            {{item.data.question}}
                        </p>
                    </div>
                    <div style="display: flex; flex-direction: row; align-items: flex-end;margin-top: 30px;">
                        <div class="two_title">数据诊断</div>
                    </div>
                    <div class="subject">
                        <p style="min-width: 48px; margin: 0">回答：</p>
                        <div v-if="item.data.data_analysis.data_analysis_answer !== ''" class="p_mg newLine" v-html="item.data.data_analysis.data_analysis_answer"></div>
                        <div v-if="item.data.data_analysis.data_analysis_answer === ''" class="p_mg newLine">该学生未作答</div>
                    </div>
                    <div v-if="!isShow" class="standard">
                        <p style="font-size: 14px">评分标准</p>
                        <div class="standard_title">
                            <div class="one">序号</div>
                            <div class="two">考核内容</div>
                            <div class="three">评分标准</div>
                            <div class="four">分值</div>
                        </div>
                        <div class="standard_content" v-for="(item1, index) in item.data.data_analysis.scoring_criteria" :key="item.content">
                            <div class="one">{{index+1}}</div>
                            <div class="two">{{item1.content}}</div>
                            <div class="three">{{item1.mark}}</div>
                            <div class="four">{{item1.score}}</div>
                        </div>
                    </div>
                    <div style="display: flex; flex-direction: row; align-items: center;margin-top: 30px;">
                        <div class="two_title">优化建议</div>
                    </div>
                    <div class="subject">
                        <p style="min-width: 48px; margin: 0">回答：</p>
                        <div class="p_mg newLine" v-html="item.data.problem_diagnosis.data_analysis_answer"></div>
                        <div v-if="item.data.problem_diagnosis.data_analysis_answer === ''" class="p_mg newLine">该学生未作答</div>

                    </div>
                    <div v-if="!isShow" class="standard">
                        <p style="font-size: 14px">评分标准</p>
                            <div class="standard_title">
                                <div class="one">序号</div>
                                <div class="two">考核内容</div>
                                <div class="three">评分标准</div>
                                <div class="four">分值</div>
                            </div>
                        <div class="standard_content" v-for="(item1, index) in item.data.problem_diagnosis.scoring_criteria" :key="item.content">
                            <div class="one">{{index+1}}</div>
                            <div class="two">{{item1.content}}</div>
                            <div class="three">{{item1.mark}}</div>
                            <div class="four">{{item1.score}}</div>
                        </div>
                    </div>
                    <div v-if="isShow" style="width: 100%;border-bottom: 1px solid #F0F0F0;padding-bottom: 20px;margin-bottom: 20px;"></div>
                    <div v-if="!isShow && dataArr.length > 1" style="width: 100%;background-color: #f5f5f5;height: 20px"></div>
                </div>
            </el-scrollbar>
        </div>
        <div v-else>
            <el-empty description="暂无数据"></el-empty>
        </div>
    </div>
</template>

<script>
    import {getDataAnalysisExamDet, getDataAnalysisTrainDet} from "../../utils/apis";

    export default {
        name: "dataAnalysisService",
        data() {
            return {
                dataArr: [],
                showBreadcrumb: true,
                scoreShow: true,
                role: null,
                isShow: false,
            }
        },
        props: {
            dataId: Object
        },
        created() {
            this.role = Number(localStorage.getItem('role'))
        },
        methods: {
            async getExamDetFn() {
                if (localStorage.getItem('role') === '1' || localStorage.getItem('role') === '0') {
                    let res = await getDataAnalysisExamDet({
                        user_id: this.dataId.id,
                        exam_id: this.dataId.cId
                    })
                    this.dataArr = res.data
                }
                if (localStorage.getItem('role') === '2') {
                    let res = await getDataAnalysisTrainDet({
                        ts_id: this.dataId.xlId
                    })
                    this.dataArr = res.data
                }
                if (localStorage.getItem('role') === '4') {
                    let res = await getDataAnalysisTrainDet({
                        ts_id: this.$route.query.xlid
                    })
                    this.dataArr = res.data
                }
            },
        },
        activated() {
            this.getExamDetFn()
        },
        mounted() {
            this.getExamDetFn()
            if (localStorage.getItem('role') === '4') {
                this.showBreadcrumb = false
            } else {
                this.showBreadcrumb = true
            }
            if (localStorage.getItem('role') === '2' || localStorage.getItem('role') === '4') {
                this.scoreShow = false
            } else {
                this.scoreShow = true
            }
            if (localStorage.getItem('role') === '2' || localStorage.getItem('role') === '1' || localStorage.getItem('role') === '0') {
                this.isShow = false
            } else {
                this.isShow = true
            }
        },
        // computed: {
        //     role: function () {
        //         return localStorage.getItem('role') !== '4'
        //     }
        // }
    }
</script>

<style scoped lang="scss">
    .standard {
        margin-left: 30px;
        margin-right: 30px;
        margin-bottom: 50px;
        .standard_title {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            width: 100%;
            background-color: #f2f2f2;
            height: 50px;
            border-radius: 10px 10px 0 0;
            font-size: 15px;
            /*padding: 10px;*/
            /*box-sizing: border-box;*/
        }
        .standard_content {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            width: 100%;
            background-color: #fcfdff;
            border-bottom: 1px solid #dedede;
            /*padding: 10px;*/
            /*box-sizing: border-box;*/
        }
        .one {
            display: flex;
            flex: 0.1;
            justify-content: center;
        }
        .two {
            flex: 0.4;
            /*padding-right: 180px;*/
            padding: 10px 180px 10px 0;
        }
        .three {
             flex: 0.4;
            /*padding-right: 180px;*/
            padding: 10px 180px 10px 0;
         }
        .four {
            display: flex;
            flex: 0.1;
            justify-content: center;
        }
    }
    .newLine {
        word-break: break-all;
        word-wrap: break-word;
    }

    .dataAnalysisService {
        width: 100%;
        height: 100%;
        border-radius: 10px;

        .dataAnalysisService-header {
            width: 100%;
            padding-bottom: 10px;
            margin-left: 30px;
            margin-top: 30px;
        }

        .dataAnalysisService_content {
            height: calc(100% - 54px);
            /*padding: 0 30px 30px 30px;*/
            box-sizing: border-box;
        }

        .dataAnalysisService_content_box {
            display: flex;
            flex-direction: column;
            .title {
                margin-top: 30px;
                display: inline-flex;
                flex-direction: row;
                line-height: 16px;
                font-size: 16px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #13131B;
                padding-left: 30px;
            }

            .two_title {
                padding-left: 30px;
                width: 100px;
                font-size: 17px;
                font-family: PingFang SC-Bold, PingFang SC;
                color: #13131B;
            }

            .subject {
                display: inline-flex;
                flex-direction: row;
                margin-top: 30px;
                padding-left: 30px;
                align-items: flex-start;
            }

            .Analysis {
                background-color: #F2F4F7;
                margin-top: 30px;
                padding: 20px;
                display: inline-flex;
                flex-direction: row;
            }
        }

    }

    /deep/ .el-scrollbar__wrap {
        overflow-x: hidden;
    }

    /deep/ .p_mg > p {
        margin: 0;
    }
</style>